import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Stack
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Chat } from "../components/chat";
import bg from "../media/bg.png";

function IllahPage() {
  return (
    <Grid
      container
      sx={{
        padding: "50px",
        height: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
      }}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
    >
      <Grid
        container
        maxWidth={"md"}
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid xs={12} md={8}>
          <Chat />
        </Grid>

        <Grid xs={12} md={8}>
          <Card>
            <CardContent>
              <Stack direction={"column"} spacing={2}>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="info">
                  Make sure you copied the generated themes from Illah or if you
                  have your own preferences of themes you can proceed.
                </Alert>
                <Button variant="contained" fullWidth LinkComponent={'a'} href="/theme">
                  My theme is ready
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default IllahPage;
