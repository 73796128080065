import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Samples } from "../components/sample";
import bg from "../media/bg.png";

function HomePage() {
  return (
    <Grid
      container
      sx={{
        padding: "50px",
        height: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        overflow: 'auto'
      }}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
    >
      <Grid
        container
        maxWidth={"md"}
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h4" textAlign={"center"}>
                Coloring Canvass Generator
              </Typography>
              <Typography variant="h6" textAlign={"center"}>
                Unleash Your Imagination: Dive into Vibrant Worlds!
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card>
            <CardContent>
              <Stack direction="column" spacing={4}>
                <Typography variant="h5">Welcome to Illah Creatives</Typography>
                <hr />
              
                <Typography fontWeight="bold">How it works?</Typography>
                <Typography fontWeight="bold">
                  1. Tell Illah something about yourself, or any wants or
                  hobbies you are into.
                </Typography>
                <Typography>
                  ex. I am 29 year old, male, who is into steam punk
                  motorcycles.
                </Typography>
                <Typography fontWeight="bold">
                  2. Copy the themes Illah generated for you based on your
                  conversation.
                </Typography>
                <Typography fontWeight="bold">
                  3. Click the `MY THEME IS READY` button and be redirected to
                  the themes page
                </Typography>
                <Typography fontWeight="bold">
                  4. Paste the copied themes from Illah and wait for a few
                  minutes to get your canvass generated in pdf form.
                </Typography>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="warning">
                  You can go directly to <a href="/theme">themes page</a> and
                  skip steps 1 to 3 if you already have themes in mind.
                </Alert>
                <Button variant="contained" LinkComponent={'a'} href="/illah">
                  TALK TO ILLAH
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Samples />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HomePage;
