import { Card, CardContent, Stack, Typography } from "@mui/material";

export function Samples() {
  return (
    <Card>
      <CardContent>
        <Stack direction="column" spacing={4}>
          <Typography variant="h6">Sample generated canvasses. [PDF Format]</Typography>
          <Typography
            style={{ color: "#fff" }}
            component={"a"}
            href="https://drive.google.com/file/d/1wVgUBBriGvy5KUm_D6MgwUnTwe7bvNrN/view?usp=sharing"
            target="#"
          >
            Universe, Planets, Stars and Earth
          </Typography>
          <Typography
            style={{ color: "#fff" }}
            component={"a"}
            href="https://drive.google.com/file/d/1X1_cCeSAPahIAtsaim65ub0JekirlAs-/view?usp=drive_link"
            target="#"
          >
            Lion, Bear, Wolf and Owl
          </Typography>
          <Typography
            style={{ color: "#fff" }}
            component={"a"}
            href="https://drive.google.com/file/d/1vMHBd46HPp405tLp3yTESetN75TbI57k/view?usp=drive_link"
            target="#"
          >
            Cyberpunk Motorcycle Hero
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
