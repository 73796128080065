import { Routes, Route, Outlet, Link } from "react-router-dom";
import HomePage from "./pages/home";
import PdfPage from "./pages/pdf";
import ThemePage from "./pages/theme";
import IllahPage from "./pages/illah";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="illah" element={<IllahPage />} />
        <Route path="theme" element={<ThemePage />} />
        <Route path="pdf" element={<PdfPage />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function Layout() {
  return (
    <>
      <Outlet />
    </>
  );
}
