import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#00ffae', // Neon Green
    },
    secondary: {
      main: '#ff00ff', // Neon Pink
    },
    error: {
      main: '#ff4d4d', // Bright Red
    },
    background: {
      default: '#000000', // Black
      paper: '#171717', // Dark Grey
    },
    text: {
      primary: '#ffffff', // White
      secondary: '#7f7f7f', // Light Grey
    },
  },
});

export default theme;