import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { useLocation } from "react-router-dom";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#353839",
    flexDirection: "row",
    padding: "10px",
    paddingTop: "40px",
  },
});

/**
 {
    frontPage: {
        title: "A bespoke coloring tome for Illah",
        image: "URL"
    },
    images: {
        title: "Casting Owls",
        image: "URL"
    }
 } 
 */

// Create Document Component
const MyDocument = (props) => {
  const { frontPage, images } = props;

  const FrontPage = () => {
    return (
      <Page size="A4" style={styles.page}>
        <View style={{ height: "100%", width: "100%" }}>
          <Text
            style={{
              color: "#fff",
              marginTop: "20px",
              marginBottom: "10px",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            {frontPage.title}
          </Text>
          <Text
            style={{
              color: "#fff",
              marginBottom: "20px",
              fontSize: "10px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            A Creative Canvas
          </Text>
          <Image
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={frontPage.image}
            alt=""
          />
          <Text
            style={{
              color: "#fff",
              marginTop: "10px",
              fontSize: "10px",
              textAlign: "center",
            }}
          >
            By Illah Creatives
          </Text>
        </View>
      </Page>
    );
  };

  const LastPage = () => {
    return (
      <Page size="A4" style={styles.page}>
        <View
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#fff",
              marginBottom: "10px",
              fontSize: "15px",
              textAlign: "center",
            }}
          >
            Developed by - Mark Ernest R. Matute
          </Text>
          <Text
            style={{
              color: "#fff",
              marginBottom: "10px",
              fontSize: "10px",
              textAlign: "center",
            }}
          >
            Github - https://github.com/MarkMatute
          </Text>
          <Text
            style={{
              color: "#fff",
              marginBottom: "10px",
              fontSize: "10px",
              textAlign: "center",
            }}
          >
            Linked In - https://www.linkedin.com/in/mark-matute/
          </Text>
          <Text
            style={{
              color: "#fff",
              marginBottom: "10px",
              fontSize: "10px",
              textAlign: "center",
            }}
          >
            Github Link of this project -
            https://github.com/MarkMatute/illah-creatives
          </Text>
          <Text
            style={{
              color: "#fff",
              marginTop: "30px",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            A CloudFlare AI Challenge entry
          </Text>
        </View>
      </Page>
    );
  };

  return (
    <Document>
      <FrontPage />
      {images.map((image, index) => {
        return (
          <Page size="A4" style={styles.page}>
            <View style={{ height: "100%", width: "100%" }}>
              <Text
                style={{
                  color: "#fff",
                  marginBottom: "10px",
                  fontSize: "30px",
                  textAlign: "right",
                }}
              >
                {image.title}
              </Text>
              <Image
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                src={image.image}
                alt=""
              />
              <Text
                style={{
                  color: "#fff",
                  marginTop: "5px",
                  fontSize: "8px",
                  textAlign: "right",
                }}
              >
                {frontPage.title} - Page {index + 1} of {images.length}
              </Text>
            </View>
          </Page>
        );
      })}
      <LastPage />
    </Document>
  );
};

const App = (props) => {
  const location = useLocation();
  return (
    <Grid container sx={{ height: "100vh", overflowY: 'hidden' }}>
      <Grid xs={12}>
        <PDFViewer showToolbar={true} width={"100%"} height={"100%"}>
          <MyDocument
            images={location.state.images}
            frontPage={location.state.frontPage}
          />
        </PDFViewer>
      </Grid>
    </Grid>
  );
};

export default App;
