import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import Markdown from 'react-markdown';
import { assistantChat } from "../cloudflare.service";

export function Chat() {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([
    {
        message: 'I am Illah, I can help you generate your coloring canvass! Just tell me your preferences, and themes will be generated for you.',
        actor: 'system'
    }
  ]);

  const sendMessage = async () => {
    setLoading(true);
    const systemResponse = await assistantChat(text);
    setLoading(false);
    console.log(systemResponse);
    setMessages([
      ...messages,
      {
        actor: "user",
        message: text,
      },
      {
        actor: "system",
        message: systemResponse.response,
      },
    ]);
    setText("");
  };

  return (
    <Card>  
      <CardContent>
        <Stack direction={"column"} style={{ height: 300, overflow: 'auto', padding: 10 }} spacing={2}>
          {messages.map((m) => {
            return (
              <>
                <hr />
                <Typography
                  style={{ fontSize: 18 }}
                  textAlign={m.actor === "system" ? "left" : "right"}
                >
                    <Markdown>{m.message}</Markdown>
                </Typography>
              </>
            );
          })}
          {loading && (
            <CircularProgress />
          )}
        </Stack>
        <TextField
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{ width: "100%", marginBottom: 10 }}
          focused
        />
        <Button variant="contained" fullWidth onClick={sendMessage}>
          Send
        </Button>
        <hr/>
       
      </CardContent>
    </Card>
  );
}
