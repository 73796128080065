import {
    Card,
    CardContent
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Typewriter from "typewriter-effect";
import bg from "../media/bg.png";

export function Loading() {
  const str = `
        Do not close the window, please wait, this might take a while. Your creative canvass(fancy word for coloring book) is being generated.
        Converting your preferred themes into title, book covers and coloring pages.
        This is made possible by Cloudflare's Worker AI and its models and CloudFlare pages.

        \nAh yeah, tasks used here are Text Generation using @hf/mistral/mistral-7b-instruct-v0.2 model,
        and Text to Image using @cf/stabilityai/stable-diffusion-xl-base-1.0.

        Simple, elegant and powerful...

    `;
  return (
    <Grid
      container
      sx={{
        padding: "50px",
        height: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        container
        maxWidth={"md"}
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid>
          <Card>
            <CardContent>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(str)
                    .callFunction(() => {
                      console.log("String typed out!");
                    })
                    .pauseFor(1000)
                    .deleteAll()
                    .callFunction(() => {
                      console.log("All strings were deleted");
                    })
                    .start();
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
