import CheckIcon from "@mui/icons-material/Check";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateImage, generateText } from "../cloudflare.service";
import { Loading } from "../components/loading";
import bg from "../media/bg.png";
  
  function ThemePage() {
    const [loading, setLoading] = useState(false);
    const [theme, setTheme] = useState(
      "Cyberpunk machines, Steam punk motorcycles"
    );
  
    const navigate = useNavigate();
  
    const getTheme = async () => {
      try {
        const prompt = `
          generate items,
  
          drawing based on ${theme},
          
          it should be simple, and output should always just be a 
          
          maximum of 5 items,
          
          each item should have a originalTitle containing the item name, 
              
          and aiTitle generate a short cool name for the item, 
          
          example 
          
          {
              originalTitle: noun,
              aiTitle: adjective noun
          },
          
          response should be a JSON object without any other text response, remove all spaces in the json and new line
          `;
  
        const response = await generateText(prompt);
  
        return Object.values(JSON.parse(response[0].response.response));
      } catch (error) {
        console.log(error);
        alert("There is an error. Try again?...");
      }
    };
  
    const getThemeTitle = async (themes) => {
      try {
        const prompt = `
          generate a title based on this combined themes, ${themes}, 
          
          format is adjective then followed by a noun, 
          
          GENERATE ONLY ONE TITLE, 
          
          DO NOT GENERATE MORE THAN ONE TITLE
          
          format response is
          
          TITLE
  
          `;
  
        const response = await generateText(prompt);
  
        return response[0].response.response;
      } catch (error) {
        console.log(error);
        alert("There is an error. Try again?...");
      }
    };
  
    const handleClick = async () => {
      try {
        setLoading(true);
  
        const genThemes = await getTheme();
  
        const frontPageTitle = await getThemeTitle(theme);
  
        const frontPageImage = await generateImage(
          `generate a colorful abstract doodle drawing of ${theme}`
        );
  
        const genImages = [];
        for (let genTheme of genThemes) {
          const tmpImage = await generateImage(
            `printable coloring page, black and white, abstract ${genTheme.originalTitle}, with aspect ratio of 3:2`
          );
          genImages.push({
            image: tmpImage,
            title: genTheme.aiTitle,
          });
        }
  
        navigate("/pdf", {
          state: {
            frontPage: {
              title: frontPageTitle,
              image: frontPageImage,
            },
            images: [...genImages],
          },
        });
  
        setLoading(false);
      } catch (error) {
        alert(
          `Hey something went wrong with the request for this theme[${theme}] ... Can you try again with different prompt?`
        );
      }
    };
  
    if (loading) {
      return <Loading />;
    }
  
    return (
      <Grid
        container
        sx={{
          padding: "50px",
          height: "100vh",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
        }}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid
          container
          maxWidth={"md"}
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
        >
  
        
          <Grid xs={12} md={8}>
            <Card>
              <CardContent>
                <Stack direction="column" spacing={2}>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="info">
                  Make sure to paste the generated themes from Illah or your
                  own preferences of themes.
                </Alert>
                  <TextField
                    focused
                    label="Canvass Theme"
                    placeholder="Cyberpunk machines, Steam punk motorcycles"
                    value={theme}
                    onChange={(e) => setTheme(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    onClick={handleClick}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      "Generate canvass using your theme"
                    )}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid xs={12} md={8}>
            <Typography
              variant="caption"
              textAlign={"center"}
              mt={100}
              color={"white"}
              component={"a"}
              href="https://www.linkedin.com/in/mark-matute/"
              target="#"
            >
              Developer - Mark Ernest R. Matute
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  
  export default ThemePage;
  