import axios from "axios";

export const generateText = async (prompt, system, user) => {
  try {
    const response = await axios.post(
      `https://text-generator.markernest-matute.workers.dev`,
      {
        prompt,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const generateImage2 = async (prompt) => {
  try {
    const response = await fetch(
      "https://i.ibb.co/WHN2Ldv/response.png",
      {
        method: "GET",
      }
    );
    const blob = await response.blob();
    const imgUrl = URL.createObjectURL(blob);

    return await new Promise(async (resolve, reject) => {
      setTimeout(() => {
        resolve(imgUrl);
      }, 2000);
    });

  } catch (error) {
    console.log(error);
  }
};

export const generateImage = async (prompt) => {
  try {
    const response = await fetch(
      "https://image-generator.markernest-matute.workers.dev",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt,
        }),
      }
    );
    const blob = await response.blob();
    const imgUrl = URL.createObjectURL(blob);
    return imgUrl;
  } catch (error) {
    console.log(error);
  }
};

export const assistantChat = async (prompt) => {
  try {
    const response = await axios.post(
      `https://art-assistant.markernest-matute.workers.dev`,
      {
        prompt,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}